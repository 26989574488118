<template>
  <div class="row">
    <div class="col-12" v-if="filter.show">
      <div class="row mx-0 flex-wrap align-items-center">
        <div class="col-4">
          <input
            type="text"
            v-model="filter.form.card__number_16_digit"
            v-debounce:400ms="onSearchByDate"
            class="form-control"
            placeholder="Karta raqami (16 xonali)"
            v-mask="'#### #### #### ####'"
          />
        </div>
        <div class="col-4">
          <input
            type="text"
            v-model="filter.form.truck_reg_number"
            v-debounce:400ms="onSearchByDate"
            class="form-control"
            placeholder="Davlat raqami"
          />
        </div>

        <div class="col-4">
          <input
            type="text"
            v-model="filter.form.counteragent_name"
            v-debounce:400ms="onSearchByDate"
            class="form-control"
            placeholder="Kontragent nomi"
          />
        </div>

        <div class="col-4">
          <input
            type="text"
            v-model="filter.form.bought_product_service"
            v-debounce:400ms="onSearchByDate"
            class="form-control"
            placeholder="Sotib olingan tovar nomi"
          />
        </div>

        <div class="col-4">
          <input
            type="text"
            v-model="filter.form.amount_min"
            v-debounce:400ms="onSearchByDate"
            class="form-control"
            placeholder="Summa min."
            @keypress="isNumberKey"
          />
        </div>
        <div class="col-4">
          <input
            type="text"
            v-model="filter.form.amount_max"
            v-debounce:400ms="onSearchByDate"
            class="form-control"
            placeholder="Summa max."
            @keypress="isNumberKey"
          />
        </div>
        <div class="col-3">
          <v-dialog
            ref="dialog"
            v-model="menu.operDateFromMenu"
            :return-value.sync="filter.form.oper_date_after"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                :value="filter.form.oper_date_after | formatDate"
                type="text"
                readonly
                v-bind="attrs"
                v-on="on"
                class="form-control"
                placeholder="Boshlanish sanasi"
              />
            </template>
            <v-date-picker v-model="filter.form.oper_date_after" scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu.operDateFromMenu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    $refs.dialog.save(filter.form.oper_date_after)

                    onSearchByDate()
                  }
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="col-3">
          <v-dialog
            ref="dialog2"
            v-model="menu.operDateToMenu"
            :return-value.sync="filter.form.oper_date_before"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <input
                :value="filter.form.oper_date_before | formatDate"
                type="text"
                readonly
                v-bind="attrs"
                v-on="on"
                class="form-control"
                placeholder="Tugash sanasi"
              />
            </template>
            <v-date-picker v-model="filter.form.oper_date_before" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu.operDateToMenu = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  () => {
                    $refs.dialog2.save(filter.form.oper_date_before)

                    onSearchByDate()
                  }
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </div>

        <div class="col-3">
          <a
            @click="()=>{
              filter.form = {};
              onSearchByDate()
            }"
            class="btn btn-text-primary btn-hover-light-primary font-weight-bold mx-2"
            style="height: 40px"
          >
            Tozalash
          </a>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="reports-toolbar custom--toolbar--btns pl-4 w-100">
        <div class="d-flex align-items-center ml-auto">
          <a
            @click="filter.show = !filter.show"
            class="btn btn-warning font-weight-bolder mr-2"
            style="height: 40px"
          >
            <span class="svg-icon svg-icon-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
              >
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <rect x="0" y="0" width="24" height="24" />
                  <path
                    d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                    opacity="0.3"
                  />
                  <path
                    d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                    fill="#000000"
                    fill-rule="nonzero"
                  />
                </g></svg
            ></span>

            {{ $t('BUTTONS.FILTER') }}</a
          >

          <toExcel
            @excel-download-emit="$emit('excel-download-emit', arguments[0])"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import toExcel from './toExcel'

// const currentYear = new Date().getFullYear()

import { takeFilledKeyValue, isNumberKey } from '@/utils'
import { monthOnly, yearOnly } from '@/utils/filters/dateFormats.js'

export default {
  components: {
    // FilterDialog,
    toExcel
  },

  filters: {
    monthOnly,
    yearOnly
  },

  watch: {
    'menu.year': {
      handler(val) {
        if (val)
          setTimeout(() => (this.$refs.yearPickerRef.activePicker = 'YEAR'))
      }
    }
    // 'menu.month': {
    //   handler(val) {
    //     if (val)
    //       setTimeout(() => (this.$refs.monthPickerRef.activePicker = 'MONTH'))
    //   }
    // }
  },

  data() {
    return {
      filter: {
        form: {
          card__number_16_digit: '',
          truck_reg_number: '',
          counteragent_name: '',
          bought_product_service: '',
          oper_date_before: '',
          oper_date_after: '',

          amount_min: '',
          amount_max: ''
        },

        show: false
      },

      menu: {
        operDateFromMenu: false,
        operDateToMenu: false
      }
    }
  },

  methods: {
    ...mapMutations(['FILTER_DIALOG_TOGGLE', 'SET_FILTER']),

    isNumberKey,

    onSearchByDate() {
      const props = takeFilledKeyValue(this.filter.form)
      this.$emit('filter:form', props)
    }
  }
}
</script>

<style>
.reports-toolbar {
  /* border: 1px solid #01534f; */
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: center;
  width: 100%;
}

.custom--toolbar--btns .el-button {
  /* padding: 0px !important;
  padding: 0 0.5rem !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  border: 1px solid transparent;
}

.reports-toolbar > i,
.el-icon-search::before {
  color: white;
}
</style>
